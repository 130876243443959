<template>
  <div class="role-container">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>人员管理</BreadcrumbItem>
      <BreadcrumbItem>角色</BreadcrumbItem>
    </Breadcrumb>

    <div ref="cardList" class="card-list">
      <RoleCard
        :key="role.id"
        v-for="(role, index) in roles"
        :role="role"
        :all-permissions="allPermission"
        :class="allPermissions[index % allPermissions.length].iconClass"
        :style="index !== 0 && (index + 1) % cardListSize === 0 ? 'margin-right: 0' : 'margin-right: 20px'"
      />
      <div class="role-add" @click="addRoleModal = true" v-if="leaveRolesSize">
        <div class="role-add-icon">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-xinzeng" />
          </svg>
        </div>
        <div class="new-role">新角色</div>
        <!--        <div v-if="leaveRolesSize > 0">(还可创建{{leaveRolesSize}}个角色)</div>-->
      </div>
      <RoleModal v-model="addRoleModal" :is-add="true" :all-permissions="allPermission" />
    </div>
  </div>
</template>
<script>
import RoleCard from './RoleCard'
import RoleModal from './RoleModal'
import roleMixin from '../../../mixins/roleMixin'
import permissionUtils from '../../../../util/permissionUtils'
import roleApi from '@api/role'
export default {
  mixins: [roleMixin],
  data: function() {
    return {
      addRoleModal: false,
      allPermission: [],
      cardListSize: 0
    }
  },
  components: {
    RoleCard,
    RoleModal
  },
  created() {
    roleApi.getPermission().then(res => {
      this.allPermission = res.res
    })
  },
  methods: {},
  computed: {
    leaveRolesSize: function() {
      return 10 - (this.roles ? this.roles.length : 0)
    },
    allPermissions: function() {
      return permissionUtils.PERMISSIONS
    }
  },
  watch: {
    '$refs.cardList.clientWidth': val => {
      let size = val / 320
      if (size * 320 - val < 20) {
        this.cardListSize = Math.round(size)
      } else {
        this.cardListSize = Math.floor(size)
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../theme/variables';

.role-container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  background-color: @layout-body-background;

  .card-list {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    padding-top: 10px;
    height: calc(100% - 18px);
    overflow: auto;

    ::v-deep .role-card-container {
      margin-bottom: 20px;
    }

    .role-add {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 300px;
      background-color: white;
      margin-bottom: 20px;
      border-radius: 4px;
      font-size: @font-size-base;
      box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.1);
      cursor: pointer;
      font-weight: @font-weight-light;
      transition: all @default-transition;

      &:hover {
        box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.3);
      }

      .role-add-icon {
        font-size: 128px;
        line-height: 68px;
        padding: 20px 0;

        svg {
          fill: #b8c2d1;
        }
      }

      .new-role {
        font-size: 24px;
        font-weight: @font-weight-normal;
      }
    }
  }
}
</style>
